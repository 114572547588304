import React, { useState, useEffect, useRef } from "react";
import "./EmbeddedVideoPlayer.css";
import { useParams } from "react-router-dom";
import { getFileUrl, getVideoFileDetails, getVideoSignedUrl, getVideoSubtitle, getVideoThumbnail, videoViewCount } from "../../features/files/fileSlice";
import { useDispatch, useSelector } from "react-redux";
import { getIpAddressService } from "../../features/ipInfo/ipInfo";
import { useDetectAdBlock } from "adblock-detect-react";

const EmbeddedVideoPlayer = () => {

  const adBlockDetected = useDetectAdBlock();

  useEffect(() => {
    if (adBlockDetected) {
      console.log('ad block detected');
    }
  }, [adBlockDetected]);
  const [videoUrl, setVideoUrl] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const { gettedfileData, getFileDetail, getThumbnail } = useSelector(
    (state) => state.file
  );

  const { ipInfoData } = useSelector((state) => state.ipInfo);

  const [videoName, setVideName] = useState();
  const [fileSize, setFileSize] = useState();
  const [isFivePercentWatched, setIsFivePercentWatched] = useState(false);
  const [subtitleList, setSubtitleList] = useState([]);

  useEffect(() => {
    dispatch(getFileUrl(id));
    dispatch(getVideoFileDetails(id)).then((response) => {
      if (response?.payload?.success === true) {
        dispatch(getVideoSignedUrl(response?.payload?.data?.name))
      }
    })
  }, [id, dispatch]);

  useEffect(() => {
    if (gettedfileData.url != "") {
      setVideoUrl(gettedfileData.url);
      setVideName(gettedfileData.fileName);
      setFileSize(gettedfileData.fileSize);
    }
  }, [gettedfileData]);

  useEffect(() => {
    dispatch(getIpAddressService());
  }, [dispatch]);

  const handleTimeUpdate = () => {
    const video = playerRef.current;
    const currentTime = video?.currentTime;
    const duration = video.duration;

    if (duration > 0) {
      const percentageWatched = (currentTime / duration) * 100;
      if (percentageWatched >= 5 && !isFivePercentWatched) {
        setIsFivePercentWatched(true);
        const data = {
          id: id,
          ip: ipInfoData.ip,
          country: ipInfoData.country,
          code: ipInfoData.country,
          isAdblock: adBlockDetected === true ? 1 : 0
        };
        dispatch(videoViewCount(data));
      }
    }
  };

  const playerRef = useRef(null);
  const [isPlyrLoaded, setIsPlyrLoaded] = useState(false);
  const [subtitleUrl, setSubtitleUrl] = useState([]);

  // Function to make the object dynamic
  const createCaptionsArray = (subtitles) => {
    return subtitles?.map((subtitle, index) => ({
      url: subtitleUrl[index],
      language: subtitle.lang.toLowerCase().substring(0, 2), // To get short language code
      label: subtitle.language.name,
      default: index === 0 // Set the first subtitle as default
    }));
  };

  // Generate dynamic captions array
  const captions = createCaptionsArray(subtitleList);


  useEffect(() => {
    let thumbnail = getFileDetail?.data?.thumbnail;
    if (thumbnail !== undefined && thumbnail !== '') {
      dispatch(getVideoThumbnail(thumbnail));
    }
    let subtitle = getFileDetail?.data?.subtitles;
    setSubtitleList(subtitle);
    let subtitleArray = [];
    const fetchSubtitles = async () => {
      if (subtitle !== undefined && subtitle.length > 0) {
        const promises = subtitle.map(item => {
          return dispatch(getVideoSubtitle(item?.subtitlefile)).then((res) => {
            return res?.payload?.data?.url;  // Return the subtitle URL
          });
        });
        subtitleArray = await Promise.all(promises); // Wait for all promises to resolve
        setSubtitleUrl(subtitleArray);
      }
    };

    fetchSubtitles();
    // Function to dynamically load a script
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
      });
    };
    let hls;
    // Load both the Plyr and HLS.js scripts
    Promise.all([
      loadScript('https://cdn.plyr.io/3.7.2/plyr.js'), // Plyr CDN
      loadScript('https://cdnjs.cloudflare.com/ajax/libs/hls.js/1.1.5/hls.min.js') // HLS.js CDN
    ])
      .then(() => {
        if (window.Plyr && window.Hls) {
          const player = new window.Plyr(playerRef.current, {
            title: 'Example Title',
            controls: [
              'play', 'progress', 'current-time', 'duration', 'mute', 'volume',
              'fullscreen', 'captions', 'settings', 'pip', 'title'
            ],
            settings: ['captions', 'quality', 'speed', 'loop'],
            captions: {
              active: true,
              update: true
            },
            // quality: {
            //   default: 576,
            //   options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240],
            //   forced: true,
            //   onChange: (e) => {
            //     console.log(e, 'anurag');
            //   },
            // },
            // thumbnail: {
            //   enabled: true,
            //   pic_num: 184,
            //   width: 178,
            //   height: 100,
            //   col: 7,
            //   row: 7,
            //   offsetX: 0,
            //   offsetY: 0,
            //   urls: [
            //   ]
            // },
          });
          var plyr = document.querySelector('.video-container>.plyr');
          var overlay = document.querySelector(".channel-icon")

          plyr.append(overlay)

          let chunkName = getFileDetail?.data?.chunkName;
          let quality = getFileDetail?.data?.quality[0]?.resolution;
          if (typeof getFileDetail?.data?.quality !== 'undefined' && getFileDetail?.data?.quality?.length > 0) {

            if (window.Hls.isSupported()) {
              hls = new window.Hls({
                xhrSetup: function (xhr, url) {
                  // If the URL is for a .ts file, fetch the signed URL
                  if (url.endsWith('.ts')) {
                    const filename = url.split('/').pop(); // Get the filename from the URL
                    dispatch(getVideoSignedUrl(`${chunkName}/${filename}`)).then((response) => {
                      const signedUrl = response?.payload?.data?.url; // Assuming the signed URL is in response payload
                      if (signedUrl && typeof signedUrl === 'string') {
                        xhr.open('GET', signedUrl, true); // Use the signed URL
                        xhr.send();
                      } else {
                        console.error("Invalid signed URL:", signedUrl);
                      }
                    });
                  }
                },
              });

              // Fetch signed URL for the master playlist and start loading the video
              dispatch(getVideoSignedUrl(`${chunkName}/${quality}.m3u8`)).then((response) => {
                const initialSignedUrl = response?.payload?.data?.url;
                if (initialSignedUrl && typeof initialSignedUrl === 'string') {
                  hls.loadSource(initialSignedUrl);
                  hls.attachMedia(playerRef.current);
                } else {
                  console.error("Invalid signed URL:", initialSignedUrl);
                }
              });
            } else if (player.canPlayType('application/vnd.apple.mpegurl')) {
            }
          }

          setIsPlyrLoaded(true);

          // Cleanup on unmount
          return () => {
            if (player) player.destroy();
          };
        }
      })
      .catch(error => console.error(error));
  }, [videoUrl, getFileDetail]); // Include videoUrl as dependency

  return (
    <>
      <div className="full-resolution">
        <video
          ref={playerRef}
          onTimeUpdate={handleTimeUpdate}
          controls
          data-plyr-config='{ "title": "Example Title" }'
          aria-label='{"title": "Example Title"}'
          poster={getThumbnail?.data?.url}
          crossOrigin="anonymous"
        >
          <source src={videoUrl} type={'video/mp4'} />
          {captions?.map((caption, index) => (
            <track
              key={index}
              src={caption.url}
              kind="subtitles"
              srcLang={caption.language}
              label={caption.label}
              default={captions[0].default}
            />
          ))}
        </video>
      </div>
    </>
  );
};

export default EmbeddedVideoPlayer;
