import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { uploadFileInitial, uploadFileFinal, uploadFileMiddle, uploadVideos } from '../../services/uploadService';
import axios from 'axios';

export const uploadFileToCloudInitial = createAsyncThunk(
    'file/uploadFileInitial',
    async ({ data, signal }, { rejectWithValue }) => {
        try {
            const source = axios.CancelToken.source();
            signal.addEventListener('abort', () => {
                source.cancel();
            });
            const response = await uploadFileInitial({ files: data, cancelToken: source.token });
            return response;
        } catch (err) {
            if (axios.isCancel(err)) {
                return rejectWithValue('Fetch aborted');
            }
            if (err.response && err.response.status === 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.message.data);
        }
    }
)

export const uploadFileToCloudMiddle = createAsyncThunk(
    'file/uploadFileMiddle',
    async ({ data, signal }, { rejectWithValue }) => {
        try {
            const source = axios.CancelToken.source();
            signal.addEventListener('abort', () => {
                source.cancel();
            });
            const response = await uploadFileMiddle({ data: data, cancelToken: source.token });
            return response;
        } catch (err) {
            if (axios.isCancel(err)) {
                return rejectWithValue('Fetch aborted');
            }
            if (err.response && err.response.status === 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.message.data);
        }
    }
)

export const uploadFileToCloudFinal = createAsyncThunk(
    'file/uploadFileFinal',
    async (data, { rejectWithValue }) => {
        try {
            const response = await uploadFileFinal(data);
            return response;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.message.data);
        }
    }
)

export const uploadVideoFile = createAsyncThunk(
    'file/uploadVideoFile',
    async ({ file, data }, { rejectWithValue }) => {
        try {
            const response = await uploadVideos(file, data);
            return response;
        } catch (err) {
            if (err.response && err.response.status === 401) {
                return rejectWithValue('Unauthorized');
            }
            rejectWithValue(err.message.data);
        }
    }
)

const uploadSlice = createSlice({
    name: 'upload',
    initialState: {
        uploadStatusInitial: 'idle',
        uploadStatusMiddle: 'idle',
        uploadStatusFinal: 'idle',
        isChunkUploaded: false,
        fileName: null,
        uploadId: null,
        partData: [],
        isFileFullyUploaded: false,
        start: null,
        end: null,
        chunkCount: null,
        chunkSize: null,
        count: 0,
        uploadedFileData: null,
        uploadCanceled: false,
        originalFileName: null,
        uploadVideoFile: null
    },
    reducers: {
        resetValues: (state) => {
            state.uploadStatusInitial = 'idle';
            state.uploadStatusMiddle = 'idle';
            state.uploadStatusFinal = 'idle';
            state.isChunkUploaded = false;
            state.fileName = null;
            state.uploadId = null;
            state.partData = [];
            state.isFileFullyUploaded = false;
            state.start = null;
            state.end = null;
            state.chunkCount = null;
            state.chunkSize = null;
            state.count = 0;
        },
        resetCancelFlag: (state) => {
            state.uploadCanceled = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFileToCloudInitial.pending, (state) => {
                state.uploadStatusInitial = 'loading';
            })
            .addCase(uploadFileToCloudInitial.fulfilled, (state, action) => {
                state.uploadStatusInitial = 'succeeded';
                state.uploadStatusMiddle = 'pending'
                state.isChunkUploaded = true;
                state.fileName = action.payload.fileName;
                state.originalFileName = action.payload.originalFileName;
                state.uploadId = action.payload.uploadId;
                state.start = action.payload.start;
                state.end = action.payload.end;
                state.chunkCount = action.payload.chunkCount;
                state.chunkSize = action.payload.chunkSize;
            })
            .addCase(uploadFileToCloudInitial.rejected, (state, action) => {
                state.uploadStatusInitial = 'failed'
                if (action.payload === 'Fetch aborted') {
                    state.uploadCanceled = true;
                }
            })
            .addCase(uploadFileToCloudMiddle.pending, (state) => {
                state.uploadStatusMiddle = 'loading';
            })
            .addCase(uploadFileToCloudMiddle.fulfilled, (state, action) => {
                if (action.payload.isFinal && action.payload.isFinal) {
                    state.uploadStatusMiddle = 'succeeded'
                    state.isChunkUploaded = true;
                } else {
                    state.uploadStatusMiddle = 'pending';
                    state.start = action.payload.start;
                    state.end = action.payload.end;
                    state.chunkCount = action.payload.chunkCount;
                    state.partData.push(action.payload.data);
                    state.count = state.count + 1;
                }
            })
            .addCase(uploadFileToCloudMiddle.rejected, (state, action) => {
                state.uploadStatusMiddle = 'failed'
                if (action.payload === 'Fetch aborted') {
                    state.uploadCanceled = true;
                }
            })
            .addCase(uploadFileToCloudFinal.pending, (state) => {
                state.uploadStatusFinal = 'loading'
            })
            .addCase(uploadFileToCloudFinal.fulfilled, (state, action) => {
                state.isFileFullyUploaded = true
                state.uploadStatusFinal = 'succeeded'
                state.uploadedFileData = action.payload.data.data;
            })
            .addCase(uploadFileToCloudFinal.rejected, (state) => {
                state.isFileFullyUploaded = true
                state.uploadStatusFinal = 'failed'
            })
            .addCase(uploadVideoFile.pending, (state) => {
                state.uploadStatusFinal = 'loading'
            })
            .addCase(uploadVideoFile.fulfilled, (state, action) => {
                state.isFileFullyUploaded = true
                state.uploadStatusFinal = 'succeeded'
                state.uploadVideoFile = action?.payload?.data;
            })
            .addCase(uploadVideoFile.rejected, (state) => {
                state.isFileFullyUploaded = true
                state.uploadStatusFinal = 'failed'
            })
    }
})

export const { resetValues, resetCancelFlag } = uploadSlice.actions;
export default uploadSlice.reducer;