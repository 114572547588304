import React from "react";
import "./footerStyle.css";
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer p-100 position-relative">
        <div className="container">
          <div className="inner-footer d-flex flex-wrap justify-content-between">
            <div className="footer-left-box">
              <div className="details-footer">
                <Link to={"/"} className="logo-footer">
                  <img src="images/logo.svg" alt="" />
                </Link>
                <p>© SaveFiles 2024 All rights reserved</p>

                {/* Social Login Options */}

                {/* <div className="social-footer">
                  <ul className="d-flex flex-wrap">
                    <li>
                      <a href="#">
                        <i className="bi bi-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="bi bi-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="bi bi-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="bi bi-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="footer-right-box d-flex flex-wrap">
              <div className="footer-menu-box">
                <h6>Company</h6>
                <ul>
                  <li>
                    <Link to={"/about-us"}>About us</Link>
                  </li>
                  <li>
                    <Link to={"/contact-us"}>Contact us</Link>
                  </li>
                  {/* <li>
                    <a href="#">Careers</a>
                  </li> */}
                  {/* <li>
                    <a href="#">Press</a>
                  </li> */}
                </ul>
              </div>
              <div className="footer-menu-box">
                <h6>Product</h6>
                <ul>
                  {/* <li>
                    <Link to={"/"}>Feature</Link>
                  </li> */}
                  <li>
                    <Link to={"/pricing"}>Pricing</Link>
                  </li>
                  {/* <li>
                    <a href="#">News</a>
                  </li>
                  <li>
                    <a href="#">Help desk</a>
                  </li> */}
                  {/* <li>
                    <Link to={"/support"}>Support</Link>
                  </li> */}
                </ul>
              </div>
              <div className="footer-menu-box">
                <h6>Services</h6>
                <ul>
                  <li>
                    <Link to={"/partner-program"}>Partner Program</Link>
                  </li>
                  {/* <li>
                    <a href="#">Content Writing</a>
                  </li>
                  <li>
                    <a href="#">SEO for Business</a>
                  </li>
                  <li>
                    <a href="#">UI Design</a>
                  </li> */}
                </ul>
              </div>
              <div className="footer-menu-box">
                <h6>Legal</h6>
                <ul>
                  <li>
                    <Link to={"/privacy-policy"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={"/copyright-policy"}>Copyright Policy</Link>
                  </li>
                  <li>
                    <Link to={"/terms-and-condition"}>Terms And Condition</Link>
                  </li>
                  {/* <li>
                    <a href="#">Return Policy</a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
