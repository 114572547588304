import { createAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllregions } from "../../services/regionService";


export const getRegions = createAsyncThunk(
    "setting/get-video-setting",
    async (thunkAPI) => {
        try {
            const response = await getAllregions()
            return response?.data;
        } catch (error) {
            if (error.response && error.response.status == 401) {
                return rejectWithValue('Invalid email or password');
            }
            return rejectWithValue(error.response.data);
        }
    }
);


export const resetState = createAction("RevertAll");

const regionSlice = createSlice({
    name: 'region',
    initialState: {
        regions: {},
        isError: false,
        isLoading: false,
        isSuccess: false,
        message: "",
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRegions.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getRegions.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.regions = action?.payload;
                state.message = action?.payload?.message
            })
            .addCase(getRegions.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(resetState, () => initialState);
    }
})

export default regionSlice.reducer;