import React, { useEffect } from "react";
import "./ShareFolder.css";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";
import { useDispatch, useSelector } from "react-redux";
import { getSharedFolderData } from "../../features/shareFolder/shareFolderSlice";
import { Helmet } from 'react-helmet';


const ShareFolder = () => {

    // MataData variable

    const title = 'SaveFiles folder';
    const description = 'SaveFiles is a cloud storage solution that offers secure, private, and easy-to-use file storage. This folder contains all your files.';
    const keywords = ['cloud storage', 'file storage', 'savefiles'];
    const ogImage = "https://staging.savefiles.com/images/baner.png";

    const path = window.location.pathname;
    // Split the path by '/' and get the last part
    const lastKey = path.substring(path.lastIndexOf('/') + 1);

    const dispatch = useDispatch();
    const { shareFolder } = useSelector(state => state.folderShare);

    useEffect(() => {
        dispatch(getSharedFolderData(lastKey));
    }, [dispatch, lastKey])

    useEffect(() => {
        const lightbox = GLightbox({
            selector: ".glightbox",
            touchNavigation: true,
            loop: true,
            autoplayVideos: false,
            autoplayAudios: true,
        });

        // Add an event listener for slide change
        lightbox.on('slide_changed', (prev, current) => {
            // Check if there is a previous slide
            if (prev) {
                // Get all media elements (video and audio) in the previous slide
                if (Boolean(prev.prev.slideNode)) {
                    const mediaElements = prev.prev.slideNode.querySelectorAll('video, audio');
                    mediaElements.forEach((media) => {
                        if (!media.paused) {
                            media.pause(); // Pause if it's playing
                            media.currentTime = 0; // Reset to the start for consistency
                        }
                    });
                }
            }
        });

        // Clean up the instance when the component unmounts
        return () => {
            lightbox.destroy();
        };
    }, [shareFolder]);

    const handleDownload = (url) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = true;
        link.click();
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                {ogImage && <meta property="og:image" content={ogImage} />}

                {/* Twitter */}
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:title" content={title} />
                <meta property="twitter:description" content={description} />
                {ogImage && <meta property="twitter:image" content={ogImage} />}
                <meta name="file:root/projects/project1/README.md" content="size:10" />
            </Helmet>
            <div className="file-upload-section webfile-upload">
                <div className="top-search p-100 pb-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="scroll-file pb-4">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-list pt-0 p-100 ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="scroll-file">
                                    {/* Check if shareFolder is empty */}
                                    {shareFolder?.files?.length > 0 ? (
                                        <ul className="box-container d-flex flex-wrap">
                                            {shareFolder?.files?.map((file, index) => (
                                                <li className="box" key={index}>
                                                    <div className="inner">
                                                        <a href={file?.signedUrl} className="glightbox">
                                                            <span className="icons">
                                                                {file.mimeType == 'video' && (
                                                                    <img src='/sharedImages/video-icons.png' alt="" />
                                                                )}
                                                                {file.mimeType == 'audio' && (
                                                                    <img src='/sharedImages/audio-icons.png' alt="" />
                                                                )}
                                                                {file.mimeType == 'image' && (
                                                                    <img src='/sharedImages/image-icons.png' alt="" />
                                                                )}
                                                                {file.mimeType == 'other' && (
                                                                    <img src='/sharedImages/pdf-icons.png' alt="" />
                                                                )}
                                                            </span>
                                                            {file.mimeType == 'video' && (
                                                                <img src='/sharedImages/upload-file-04.png' alt="" />
                                                            )}
                                                            {file.mimeType == 'audio' && (
                                                                <img src='/sharedImages/upload-file-06.png' alt="" />
                                                            )}
                                                            {file.mimeType == 'image' && (
                                                                <img src={file?.signedUrl} alt="" />
                                                            )}
                                                            {file.mimeType == 'other' && (
                                                                <img src='/sharedImages/upload-file-06.png' alt="" />
                                                            )}
                                                            <span className="btn-slider">{file?.originalFileName}</span>
                                                            <button
                                                                className="download-btn"
                                                                onClick={() => handleDownload("UploadFile2")}
                                                            >
                                                                <img src='/sharedImages/dropdown-img.png' alt="Download" />
                                                            </button>
                                                        </a>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <div className="no-items-found">
                                            <h3>No items found in this folder.</h3>
                                            <a className="btn-primary width-custom" href="/">Go Bach to home</a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShareFolder