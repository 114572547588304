import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getSharedFolder = async (key) => {
    const token = localStorage.getItem('token') || null
    return await axios.get(`${API_URL}/user/get-shared-folder/${key}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const getSharedFolderSignedinUrl = async (data) => {
    const url = process.env.REACT_APP_API_URL;
    if (!url) {
        throw new Error("Not able to find the url");
    }
    const response = await axios.get(`${url}/user/getFileUrlV2?filename=${data}`);
    return response;
}