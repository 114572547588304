import { createAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSharedFolder, getSharedFolderSignedinUrl } from "../../services/shareFolderService";


export const getSharedFolderData = createAsyncThunk(
    "shareFolder/get-folder-data",
    async (data, { rejectWithValue }) => {
        try {
            const response = await getSharedFolder(data)
            return response.data;
        } catch (error) {
            if (error.response && error.response.status == 401) {
                return rejectWithValue('Invalid email or password');
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const getSharedFolderUrl = createAsyncThunk(
    "shareFolder/get-folder-url",
    async (data, { rejectWithValue }) => {
        try {
            const response = await getSharedFolderSignedinUrl(data)
            return response.data;
        } catch (error) {
            if (error.response && error.response.status == 401) {
                return rejectWithValue('Invalid email or password');
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const resetState = createAction("RevertAll");

const shareFolderSlice = createSlice({
    name: 'shareFolder',
    initialState: {
        shareFolder: {},
        shareFolderUrl: "",
        isError: false,
        isLoading: false,
        isSuccess: false,
        message: "",
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSharedFolderData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSharedFolderData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.shareFolder = action?.payload?.data;
                state.message = action?.payload?.message
            })
            .addCase(getSharedFolderData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload || action.error.message;
            })
            .addCase(getSharedFolderUrl.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getSharedFolderUrl.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.shareFolderUrl = action?.payload?.data;
                state.message = action?.payload?.message
            })
            .addCase(getSharedFolderUrl.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.payload || action.error.message;
            })
            .addCase(resetState, () => initialState);
    }
})

export default shareFolderSlice.reducer;