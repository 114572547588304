import React, { useEffect, useState } from 'react';
import Boxyellow from '../../assets/images/box-yellow.png';
import Bluebox from '../../assets/images/box-blue.png';
import Redbox from '../../assets/images/red-box.png';
import { useDispatch, useSelector } from 'react-redux';
import { contactUs, resetContactUsState } from '../../features/profile/profileSlice';
import { toast } from 'react-toastify';

const ContactUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('General Support');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    const [dmcaFieldsVisible, setDmcaFieldsVisible] = useState(false); // New state for DMCA fields
    const [legalName, setLegalName] = useState('');
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [phone, setPhone] = useState('');
    const [streetCity, setStreetCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState('');
    const [infringingContentUrls, setInfringingContentUrls] = useState('');
    const [infringingUrls, setInfringingUrls] = useState('');
    const [radioOption, setRadioOption] = useState(''); // New state for radio buttons
    const [rightHolderName, setRightHolderName] = useState('');
    const [rightHolderCountry, setRightHolderCountry] = useState('');

    const dispatch = useDispatch();
    const { contactUsStatus } = useSelector(state => state.profile);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (name === '' || email === '' || subject === '' || message === '') {
            setError(true);
            return;
        }
        if (subject === 'DMCA' && (legalName === '' || company === '' || streetCity === '' || infringingContentUrls === '' || infringingUrls === '')) {
            setError(true);
            return;
        }

        setError(false);
        const data = {
            name,
            email: email,  // Adding 'mailto:' prefix to email
            subject,
            message,
            ...(dmcaFieldsVisible && {
                moreDetails: {
                    legalName,
                    company,
                    position,
                    phone,
                    streetCity,
                    zipCode,
                    country,
                    infringingContentUrls,
                    infringingUrls,
                    radioOption,
                    ...(radioOption === "Agent" && {
                        rightHolderName,  // Include only if radio option is 'Agent'
                        rightHolderCountry // Include only if radio option is 'Agent'
                    })
                }
            })
        };
        dispatch(contactUs(data));
    };

    useEffect(() => {
        if (contactUsStatus === "succeeded") {
            toast.success("Message Sent Successfully!", {});
            setName('');
            setEmail('');
            setSubject('General Support');
            setMessage('');
            setLegalName('');
            setCompany('');
            setPosition('');
            setPhone('');
            setStreetCity('');
            setZipCode('');
            setCountry('');
            setInfringingContentUrls('');
            setInfringingUrls('');
            dispatch(resetContactUsState());
            return;
        }
        if (contactUsStatus === "failed") {
            toast.error("Something went wrong!", {});
            dispatch(resetContactUsState());
            return;
        }
    }, [contactUsStatus]);

    useEffect(() => {
        if (subject === "DMCA") {
            setDmcaFieldsVisible(true);
        } else {
            setDmcaFieldsVisible(false);
        }
    }, [subject]);

    return (
        <section className='login-sec p-100 position-relative'>
            <div className='banner-animation-icons'>
                <span className="yellow-box">
                    <img src={Boxyellow} alt="" />
                </span>
                <span className="box-blue">
                    <img src={Bluebox} alt="" />
                </span>
                <span className="red-box">
                    <img src={Redbox} alt="" />
                </span>
            </div>

            <div className='form-sec-details position-relative'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-10 col-md-12 text-center'>
                            <div className="heading-head mb-5">
                                <span className="sub-heading">Get in Touch</span>
                                <h2>Contact Us</h2>
                            </div>
                        </div>

                        <div className='form-box-main'>
                            <div className='form-sec'>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4 position-relative">
                                        <label htmlFor="name" className='form-label'>Full Name <span className='error-defin'>*</span></label>
                                        <input type="text" required className="form-control" id="name" value={name} placeholder='Enter Name' onChange={(e) => setName(e.target.value)} />
                                    </div>
                                    <div className="mb-4 position-relative">
                                        <label htmlFor="email" className='form-label'>Email address  <span className='error-defin'>*</span></label>
                                        <input type="email" required className="form-control" id="email" value={email} placeholder='Enter email address' onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div className="mb-4 position-relative">
                                        <label htmlFor="subject" className='form-label'>Subject  <span className='error-defin'>*</span></label>
                                        <select required className="form-control form-select" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)}>
                                            <option value="General Support">General Support</option>
                                            <option value="DMCA">DMCA</option>
                                            <option value="Business Inquiries">Business Inquiries</option>
                                            <option value="Security-Related Support">Security-Related Support</option>
                                        </select>
                                    </div>

                                    {dmcaFieldsVisible && (
                                        <>
                                            {/* New radio buttons */}
                                            <div className="mb-4 position-relative">
                                                <div>
                                                    <input type="radio" id="owner" name="dmca-radio" value="Owner" onChange={(e) => setRadioOption(e.target.value)} />
                                                    <label className="form-label" htmlFor='owner'>I am the owner of the infringing content</label>
                                                </div>
                                                <div>
                                                    <input type="radio" id="agent" name="dmca-radio" value="Agent" onChange={(e) => setRadioOption(e.target.value)} />
                                                    <label className="form-label" htmlFor="agent">I am an agent, commissioned and allowed to file this takedown notice on behalf of the rightholder</label>
                                                </div>
                                            </div>
                                            <div className="mb-4 position-relative">
                                                <label htmlFor="legalName" className='form-label'>Full Legal Name <span className='error-defin'>*</span></label>
                                                <input type="text" className="form-control" id="legalName" value={legalName} placeholder='Enter Legal Name' onChange={(e) => setLegalName(e.target.value)} />
                                            </div>
                                            {/* Conditionally render Right Holder fields if "Agent" is selected */}
                                            {radioOption === "Agent" && (
                                                <div className="mb-4 position-relative">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="rightHolderName" className="form-label">Right Holder's Name / Company Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="rightHolderName"
                                                                placeholder="Enter Right Holder's Name or Company"
                                                                value={rightHolderName}
                                                                onChange={(e) => setRightHolderName(e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="rightHolderCountry" className="form-label">Right Holder's Country</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="rightHolderCountry"
                                                                placeholder="Enter Right Holder's Country"
                                                                value={rightHolderCountry}
                                                                onChange={(e) => setRightHolderCountry(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="mb-4 position-relative">
                                                <label htmlFor="company" className='form-label'>Company <span className='error-defin'>*</span></label>
                                                <input type="text" className="form-control" id="company" value={company} placeholder='Enter Company' onChange={(e) => setCompany(e.target.value)} />
                                            </div>
                                            <div className="mb-4 position-relative">
                                                <label htmlFor="position" className='form-label'>Position</label>
                                                <input type="text" className="form-control" id="position" value={position} placeholder='Enter Position' onChange={(e) => setPosition(e.target.value)} />
                                            </div>
                                            <div className="mb-4 position-relative">
                                                <label htmlFor="phone" className='form-label'>Phone (optional)</label>
                                                <input type="text" className="form-control" id="phone" value={phone} placeholder='Enter Phone Number' onChange={(e) => setPhone(e.target.value)} />
                                            </div>
                                            <div className="mb-4 position-relative">
                                                <label htmlFor="streetCity" className='form-label'>Street, City <span className='error-defin'>*</span></label>
                                                <input type="text" className="form-control" id="streetCity" value={streetCity} placeholder='Enter Street, City' onChange={(e) => setStreetCity(e.target.value)} />
                                            </div>
                                            <div className="mb-4 position-relative">
                                                <label htmlFor="zipCode" className='form-label'>Zip / Postal Code</label>
                                                <input type="text" className="form-control" id="zipCode" value={zipCode} placeholder='Enter Zip / Postal Code' onChange={(e) => setZipCode(e.target.value)} />
                                            </div>
                                            <div className="mb-4 position-relative">
                                                <label htmlFor="country" className='form-label'>Country</label>
                                                <input type="text" className="form-control" id="country" value={country} placeholder='Enter Country' onChange={(e) => setCountry(e.target.value)} />
                                            </div>
                                            <div className="mb-4 position-relative">
                                                <label htmlFor="infringingContentUrls" className='form-label'>URLs where the allegedly infringing content was found <span className='error-defin'>*</span></label>
                                                <textarea className="form-control" id="infringingContentUrls" rows="3" value={infringingContentUrls} placeholder='Enter URLs' onChange={(e) => setInfringingContentUrls(e.target.value)}></textarea>
                                            </div>
                                            <div className="mb-4 position-relative">
                                                <label htmlFor="infringingUrls" className='form-label'>URLs of the allegedly infringing content <span className='error-defin'>*</span></label>
                                                <textarea className="form-control" id="infringingUrls" rows="3" value={infringingUrls} placeholder='Enter URLs' onChange={(e) => setInfringingUrls(e.target.value)}></textarea>
                                            </div>
                                        </>
                                    )}

                                    <div className="mb-4 position-relative">
                                        <label htmlFor="message" className='form-label'>Message <span className='error-defin'>*</span></label>
                                        <textarea required className="form-control" id="message" rows="3" value={message} placeholder='Enter Message' onChange={(e) => setMessage(e.target.value)}></textarea>
                                    </div>

                                    {error && <p style={{ color: 'red' }}>Please fill out the required fields.</p>}

                                    <button type="submit" className="btn btn-primary w-100 blue-btn">Send</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
