import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getAllregions = async () => {
    const token = localStorage.getItem('token') || null
    return await axios.get(`${API_URL}/user/regions`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};