import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { getFileUrl } from "../../features/files/fileSlice"; // Redux action
import { useDispatch, useSelector } from "react-redux";

const loadPlyrAssets = () => {
    const plyrCSS = document.createElement('link');
    plyrCSS.rel = 'stylesheet';
    plyrCSS.href = 'https://cdn.plyr.io/3.6.12/plyr.css';
    document.head.appendChild(plyrCSS);

    const plyrJS = document.createElement('script');
    plyrJS.src = 'https://cdn.plyr.io/3.6.12/plyr.polyfilled.js';
    plyrJS.async = true;

    return new Promise((resolve) => {
        plyrJS.onload = () => {
            resolve();
        };
        document.body.appendChild(plyrJS);
    });
};

const AudioPlayer = () => {
    const [audioUrl, setAudioUrl] = useState("");
    const { id } = useParams();
    const dispatch = useDispatch();
    const { gettedfileData, loading, error } = useSelector((state) => state.file);
    const [audioName, setAudioName] = useState();
    const [fileSize, setFileSize] = useState();
    const audioRef = useRef(null);
    const playerRef = useRef(null); // For storing the Plyr instance

    useEffect(() => {
        dispatch(getFileUrl(id)); // Fetch the audio URL using the ID from params
    }, [id, dispatch]);

    useEffect(() => {
        if (gettedfileData.url !== "") {
            setAudioUrl(gettedfileData.url);
            setAudioName(gettedfileData.fileName);
            setFileSize(gettedfileData.fileSize);
        }
    }, [gettedfileData]);

    // Load Plyr assets only once when the component mounts
    useEffect(() => {
        loadPlyrAssets().then(() => {
            playerRef.current = new window.Plyr(audioRef.current, {
                controls: ['play', 'progress', 'current-time', 'mute', 'volume'],
                autoplay: false, // Disable autoplay
            });
        });

        return () => {
            if (playerRef.current) {
                playerRef.current.destroy();
            }
        };
    }, []);

    // Reset and load new source when audioUrl changes
    useEffect(() => {
        if (playerRef.current && audioUrl) {
            // Stop the previous audio
            playerRef.current.stop();

            // Reset the audio element
            audioRef.current.src = ""; // Clear the source first
            audioRef.current.load();   // Reset the element

            // Set the new source
            audioRef.current.src = audioUrl;
            playerRef.current.source = {
                type: 'audio',
                sources: [
                    {
                        src: audioUrl,
                        type: 'audio/mp3',
                    },
                ],
            };
        }
    }, [audioUrl]);

    return (
        <section className="audio-single-sec p-100">
            <div className="container">
                <div className="row">
                    {loading && <p>Loading audio file...</p>}
                    {error && <p>Error loading audio: {error}</p>}
                    {!loading && !error && (
                        <>
                            <div className="col-md-12 text-center">
                                <h2>{audioName}</h2>
                            </div>
                            <div className="col-md-12">
                                <div className="audio-player-container">
                                    <audio ref={audioRef} className="plyr">
                                        {/* The source tag can be removed if Plyr manages it */}
                                    </audio>
                                </div>
                            </div>
                            <div className="col-md-12 text-center mt-4">
                                <a href={audioUrl} download className="download-btn btn btn-primary blue-btn">
                                    Download Audio
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};

export default AudioPlayer;
