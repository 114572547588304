import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFileUrl } from "../../features/files/fileSlice";
import { useDispatch, useSelector } from "react-redux";

const ImageViewer = () => {
    const [imageUrl, setImageUrl] = useState("");
    const { id } = useParams();
    const dispatch = useDispatch();
    const { gettedfileData, getFileUrlStatus } = useSelector(
        (state) => state.file
    );

    const [imageName, setImageName] = useState();
    const [fileSize, setFileSize] = useState();

    useEffect(() => {
        dispatch(getFileUrl(id));
    }, [id, dispatch]);

    useEffect(() => {
        if (gettedfileData.url !== "") {
            setImageUrl(gettedfileData.url);
            setImageName(gettedfileData.fileName);
            setFileSize(gettedfileData.fileSize);
        }
    }, [gettedfileData]);

    return (
        <>
            <section className="image-single-sec p-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-head mb-5 text-center">
                                <span className="sub-heading mb-1">Welcome Images</span>
                                <h2>{imageName}</h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="top-image">
                                {imageUrl && (
                                    <img
                                        src={imageUrl}
                                        alt={imageName}
                                        style={{ width: "100%", height: "auto" }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="video-btn-btm text-center mt-3">
                                <a
                                    href={imageUrl}
                                    className="btn btn-primary w-100 blue-btn"
                                    download
                                >
                                    Download Image
                                </a>
                                {/* <p className="subheading">Size: {fileSize} MB</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ImageViewer;
