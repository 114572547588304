import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getProfileDetail = async () => {
    try {
        const token = localStorage.getItem('token') || null
        return await axios.get(`${API_URL}/user/profile`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.error('Unauthorized! Redirecting to login...');
            // Handle 401 here, e.g., redirect to login
            localStorage.removeItem('token')
            window.location.href = '/signin'; // Example redirection to login page
        } else {
            console.error('Error:', error.message);
        }
    }
};

export const updatePassword = async (data) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No authentication token found');
    }

    const url = `${API_URL}/user/profile/password`;

    return await axios.put(url, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const updateProfile = async (data) => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token')
    }

    const url = `${API_URL}/user/profile`

    return await axios.put(url, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export const getNotificationsService = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token')
    }

    const url = `${API_URL}/user/get-notifications`

    return await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export const contactUsService = async (data) => {
    const url = `${API_URL}/user/contact-us`;

    return await axios.post(url, data);
}

export const getDashboardDetailService = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token')
    }

    const url = `${API_URL}/user/dashboard`

    return await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}

export const getTransactionHistoryService = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No token')
    }

    const url = `${API_URL}/user/get-payout-data`

    return await axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}