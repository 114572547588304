import { combineReducers } from "@reduxjs/toolkit";
import authReducer, { logout } from "../features/auth/authSlice";
import profileReducer from "../features/profile/profileSlice";
import fileReducer from "../features/files/fileSlice";
import uploadReducer from '../features/upload/uploadSlice';
import remoteUploadReducer from '../features/remoteUpload/remoteUploadSlice'
import planReducer from '../features/plans/planSlice'
import ipReducer from '../features/ipInfo/ipInfo';
import videosettingSlice from "../features/videoSetting/videosettingSlice";
import regionSlice from "../features/region/regionSlice";
import commonSlice from "../features/common/commonSlice";
import shareFolderSlice from "../features/shareFolder/shareFolderSlice";

const appReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  file: fileReducer,
  upload: uploadReducer,
  remoteUpload: remoteUploadReducer,
  plans: planReducer,
  ipInfo: ipReducer,
  videosetting: videosettingSlice,
  regions: regionSlice,
  common: commonSlice,
  folderShare: shareFolderSlice,
});

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    state = undefined;  // This will reset the state
  }

  return appReducer(state, action)
}

export default rootReducer;
