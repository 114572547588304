import { createAction, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getVideoSetting, updateVideoSetting, uploadChannelLogo, updateAdSetting } from "../../services/videoSettingService";


export const getVideoSettings = createAsyncThunk(
    "setting/get-video-setting",
    async (thunkAPI) => {
        try {
            const response = await getVideoSetting()
            return response.data;
        } catch (error) {
            if (error.response && error.response.status == 401) {
                return rejectWithValue('Invalid email or password');
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateVideoSettings = createAsyncThunk(
    'setting/update-video-setting',
    async (obj, { rejectWithValue }) => {
        try {
            const response = await updateVideoSetting(obj);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateAdSettings = createAsyncThunk(
    'setting/update-ad-setting',
    async (obj, { rejectWithValue }) => {
        try {
            const response = await updateAdSetting(obj);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Thunk for uploading the channel logo
export const uploadChannelLogos = createAsyncThunk(
    'setting/uploadChannelLogo',
    async (logoFile, { rejectWithValue }) => {
        try {
            const response = await uploadChannelLogo(logoFile);
            return response; // Assuming the response includes the uploaded file URL
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const resetState = createAction("RevertAll");

const videosettingSlice = createSlice({
    name: 'setting',
    initialState: {
        videoSetting: {},
        isError: false,
        isLoading: false,
        isSuccess: false,
        message: "",
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVideoSettings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getVideoSettings.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.videoSetting = action?.payload?.data;
                state.message = action?.payload?.message
            })
            .addCase(getVideoSettings.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateVideoSettings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateVideoSettings.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.videoSetting = action.payload;
            })
            .addCase(updateVideoSettings.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(updateAdSettings.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateAdSettings.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.AdSetting = action.payload;
            })
            .addCase(updateAdSettings.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(uploadChannelLogos.pending, (state) => {
                state.isLoading = true;
                state.isError = null;
            })
            .addCase(uploadChannelLogos.fulfilled, (state, action) => {
                state.isLoading = false;
                state.logoUrl = action.payload; // assuming response contains logoUrl
            })
            .addCase(uploadChannelLogos.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = action.payload;
            })
            .addCase(resetState, () => initialState);
    }
})

export default videosettingSlice.reducer;