import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getVideoSetting = async () => {
    const token = localStorage.getItem('token') || null
    return await axios.get(`${API_URL}/user/get-video-settings`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const updateVideoSetting = async (obj) => {
    const token = localStorage.getItem('token') || null
    return await axios.put(`${API_URL}/user/update-video-setting`, obj, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const updateAdSetting = async (obj) => {
    const token = localStorage.getItem('token') || null
    return await axios.post(`${API_URL}/user/update-ads-setting`, obj, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

// Upload the channel logo
export const uploadChannelLogo = async (channelLogo) => {
    const token = localStorage.getItem('token') || null;
    const formData = new FormData();
    formData.append('channelLogo', channelLogo);
    const response = await axios.post(`${API_URL}/user/update-logo`, formData, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    });

    // Return only the relevant part of the response to avoid non-serializable headers
    return response.data; // Or return the necessary parts of the response
};