import React, { useEffect, useRef } from "react";
import Boxyellow from '../../assets/images/box-yellow.png'
import Bluebox from '../../assets/images/box-blue.png'
import Redbox from '../../assets/images/red-box.png'
import Datamap from 'datamaps';
import './PartnerProgram.css';
import GetContact from "../Landing/GetContactSection/GetContact";
import { useDispatch, useSelector } from "react-redux";
import { getRegions } from "../../features/region/regionSlice";

const DatamapComponent = () => {
    const mapContainerRef = useRef(null);

    useEffect(() => {
        const map = new Datamap({
            element: mapContainerRef.current,
            responsive: true,
            projection: 'mercator',
            fills: {
                defaultFill: '#abdda4',
                REGIO_1: '#6758ff',
                REGIO_2: '#ffc054',
                REGIO_3: '#f85677',
                REGIO_4: '#a942ea',
                REGIO_5: '#EE461A',
                UNREGIO_ONEKNOWN: 'rgb(0,0,0)'
            },
            data: {
                UK: { fillKey: 'REGIO_1' },
                USA: { fillKey: 'REGIO_1' },
                AUS: { fillKey: 'REGIO_1' },
                NOR: { fillKey: 'REGIO_1' },
                DEU: { fillKey: 'REGIO_1' },
                DNK: { fillKey: 'REGIO_2' },
                SWE: { fillKey: 'REGIO_2' },
                FIN: { fillKey: 'REGIO_2' },
                FRA: { fillKey: 'REGIO_2' },
                AUT: { fillKey: 'REGIO_2' },
                CAN: { fillKey: 'REGIO_2' },
                // PRT: { fillKey: 'REGIO_2' },
                // ESP: { fillKey: 'REGIO_2' },
                // CHN: { fillKey: 'REGIO_3' },
                NLD: { fillKey: 'REGIO_3' },
                CHE: { fillKey: 'REGIO_3' },
                ITA: { fillKey: 'REGIO_3' },
                BEL: { fillKey: 'REGIO_3' },
                IRL: { fillKey: 'REGIO_3' },
                NZL: { fillKey: 'REGIO_3' },
                ESP: { fillKey: 'REGIO_3' },
                // NPL: { fillKey: 'REGIO_3' },
                // BGD: { fillKey: 'REGIO_3' },
                // LKA: { fillKey: 'REGIO_3' },
                // MMR: { fillKey: 'REGIO_3' },
                // THA: { fillKey: 'REGIO_3' },
                // VNM: { fillKey: 'REGIO_3' },
                // KHM: { fillKey: 'REGIO_3' },
                // KAZ: { fillKey: 'REGIO_3' },
                // MNG: { fillKey: 'REGIO_3' },
                // AFG: { fillKey: 'REGIO_3' },
                // IRN: { fillKey: 'REGIO_3' },
                // TKM: { fillKey: 'REGIO_3' },
                // TJK: { fillKey: 'REGIO_3' },
                // UZB: { fillKey: 'REGIO_3' },
                // KGZ: { fillKey: 'REGIO_3' },
                // PAK: { fillKey: 'REGIO_3' },
                // JPN: { fillKey: 'REGIO_3' },
                // BTN: { fillKey: 'REGIO_3' },
                // LAO: { fillKey: 'REGIO_3' },
                // MYS: { fillKey: 'REGIO_3' },
                // PHL: { fillKey: 'REGIO_3' },
                // PRK: { fillKey: 'REGIO_3' },
                // KOR: { fillKey: 'REGIO_3' },
                // TWN: { fillKey: 'REGIO_3' },
                // PNG: { fillKey: 'REGIO_3' },
                BIH: { fillKey: 'REGIO_4' },
                BRA: { fillKey: 'REGIO_4' },
                BGR: { fillKey: 'REGIO_4' },
                CZE: { fillKey: 'REGIO_4' },
                CYP: { fillKey: 'REGIO_4' },
                GRC: { fillKey: 'REGIO_4' },
                HKG: { fillKey: 'REGIO_4' },
                IND: { fillKey: 'REGIO_4' },
                IDN: { fillKey: 'REGIO_4' },
                MEX: { fillKey: 'REGIO_4' },
                POL: { fillKey: 'REGIO_4' },
                ROU: { fillKey: 'REGIO_4' },
                RUS: { fillKey: 'REGIO_4' },
                SRB: { fillKey: 'REGIO_4' },
                SVK: { fillKey: 'REGIO_4' },
                ARE: { fillKey: 'REGIO_4' },
                JPN: { fillKey: 'REGIO_4' },
                // SWE: { fillKey: 'REGIO_5' },
                // IRL: { fillKey: 'REGIO_5' },
                // ITA: { fillKey: 'REGIO_5' },
                // NZL: { fillKey: 'REGIO_5' },
            },
            geographyConfig: {
                borderColor: 'black', // Makes country borders transparent
                highlightBorderColor: 'transparent', // Makes highlight borders transparent
            }
        });

        const handleResize = () => {
            map.resize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="canvash-map">
            <div id="mapcontainer" ref={mapContainerRef} style={{ width: '768px', height: '360px', color: "black", objectFit: "cover", margin: "0 auto", padding: "0" }}></div>
        </div>
    );
};

const PartnerProgram = () => {
    const dispatch = useDispatch();

    const { regions } = useSelector(state => state.regions);

    useEffect(() => {
        dispatch(getRegions())
    }, [dispatch]);

    return (
        <>
            <section className='PartnerProgram-sec pb-3 p-100 position-relative'>
                <div className='banner-animation-icons'>
                    <span className="yellow-box">
                        <img src={Boxyellow} alt="" />
                    </span>
                    <span className="box-blue">
                        <img src={Bluebox} alt="" />
                    </span>
                    <span className="red-box">
                        <img src={Redbox} alt="" />
                    </span>
                </div>

                <div className='top-partner-sec'>
                    <div className='container'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-12 col-md-12 text-center'>
                                <div className="heading-head mb-5">
                                    <span className="sub-heading">Benefits</span>
                                    <h2>Partner Program</h2>
                                    <p>Earn money by hosting and sharing your content with SaveFiles.</p>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className='partner-details'>
                                    <p><strong>Earn a fixed amount per 10,000 downloads or streams. The payment amount is defined by the origin country referred to in the Level table / Worldmap below. To earn money with SaveFiles you just need to follow these simple rules:</strong></p>
                                    <ul className='listing-savefile'>
                                        <li>Minimum payout amount - $10.00</li>
                                        <li>Downloads or Streams are counted up to 2 within 24 hours per user.</li>
                                        <li>A statement under penalty of perjury that you have a good-faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled and</li>
                                        <li>Rewards won't be counted if advertising is blocked.</li>
                                        <li>There are no rewards for automated downloads.</li>
                                        <li>Large amounts of abuse notices against your files may result in suspension.</li>
                                        <li>Traffic from third party apps will not count.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='datamaps-section position-relative'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='maps-sec'>
                                <DatamapComponent />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="region-partner-program-sec pt-5 p-100">
                <div className="top-region-partner-program">
                    <div className="container">
                        <div className="row align-content-center justify-content-center">
                            <div className="col-lg-10 col-md-12 d-flex align-content-center flex-wrap">
                                <div className="btn-box btn-region-01"><span></span> Region 1</div>
                                <div className="btn-box btn-region-02"><span></span> Region 2</div>
                                <div className="btn-box btn-region-03"><span></span> Region 3</div>
                                <div className="btn-box btn-region-04"><span></span> Region 4</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-region-partner-program mt-5 ">
                    <div className="container">
                        <div className="row">
                            {
                                regions?.data?.map((region, index) => (
                                    <div key={index} className="col-lg-3 col-md-6 mb-3">
                                        <div className={`box-pricing-partner region-0${index + 1} h-100`}>
                                            <h3 className={`btn-pricing btn-pricing-region-0${index + 1}`}>Reward ${region?.rewardAmount}</h3>
                                            <h4>Region {index + 1}</h4>
                                            <ul className="list-group">
                                                {
                                                    region?.countries?.map((country) => (
                                                        <li key={country.id}>{country.countryName}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="col-lg-12 col-md-12 mt-3">
                                <div className="box-pricing-bottom">
                                    <p>*All Countries which are not listed above will be rewarded with $4.50.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <GetContact />

        </>
    )
}

export default PartnerProgram
